<template>
  <div class="appointment-list-view">
    <app-header icon="stethoscope" title="Especialidades"></app-header>

    <section>
      <div class="card">
        <div class="card-header">
          <div class="card-header-content">
            <router-link
              :to="{
                name: 'specialties.save',
              }"
            >
              <b-button type="is-primary" icon-right="plus">Nova</b-button>
            </router-link>
          </div>
          <div class="card-header-buttons">
            <b-tooltip label="Exportar XLS">
              <b-button
                @click="exportSpecialties()"
                type="is-dark"
                size="is-small"
                icon-left="file-excel"
                :disabled="isExporting"
                :loading="isExporting"
              >
                Exportar
              </b-button>
            </b-tooltip>
          </div>
        </div>
        <div class="card-content">
          <div class="row">
            <div class="col-md-5">
              <b-field>
                <b-input
                  v-model="searchName"
                  placeholder="Nome"
                  type="text"
                  class="m-1"
                ></b-input>
              </b-field>
            </div>
          </div>
          <b-table
            @page-change="onPageChange"
            :data="data"
            :total="total"
            :per-page="20"
            :loading="isLoading"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="name" label="Nome">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Opções"
              cell-class="width-100"
            >
              <router-link
                :to="{
                  name: 'specialties.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>

              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import SpecialtiesService from '@/services/specialties.service';
import debounce from 'lodash/debounce';

export default {
  data: () => ({
    isExporting: false,
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    searchName: '',
  }),
  watch: {
    searchName: debounce(function () {
      this.page = 1;
      this.load();
    }, 400),
  },
  methods: {
    load() {
      this.isLoading = true;

      SpecialtiesService.get({
        page: this.page,
        name: this.searchName,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.load();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Excluindo Especialidade',
        message:
          'Tem certeza que deseja <b>excluir</b> esta especialidade? Essa ação não pode ser desfeita.',
        cancelText: 'Cancelar',
        confirmText: 'Excluir Especialidade',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },
    delete(id) {
      SpecialtiesService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open('Especialidade excluída com sucesso.');
          this.load();
        })
        .catch(({ response }) => {
          if (typeof response != 'undefined' && response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || 'Erro ao tentar excluir a Especialidade.',
              'error',
              4000
            );
          }
        });
    },
    exportSpecialties() {
      this.isExporting = true;

      let filters = {
        name: this.searchName || '',
      };

      SpecialtiesService.export({ ...filters })
        .then(({ data }) => {
          const fileName = data.split('/')[data.split('/').length - 1];
          const fileLink = document.createElement('a');

          fileLink.target = '_blank';
          fileLink.href = data;
          fileLink.setAttribute('download', fileName);
          fileLink.setAttribute('style', 'display:none;');

          document.body.appendChild(fileLink);
          fileLink.click();

          fileLink.remove();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || 'Erro ao realizar exportação.';
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss"></style>
